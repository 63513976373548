<template>
    <div class="content_container customer_terms">
        <div class="content">
            <div class="banner">
                <h1>금융소비자보호</h1>
            </div>
			<div class="m_terms_menu_wrapper">
				<ul class="terms_menu">
					<li class="current">
						<a @click="toggleMenu">금리인하요구권</a>
					</li>
					<li>
						<router-link to="/customer/financeprotect" @click="toggleMenu">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw" @click="toggleMenu">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen" @click="toggleMenu">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination" @click="toggleMenu">위법계약해지권</router-link>
					</li>
				</ul>
			</div>
            <section class="terms">
				<ul class="terms_menu">
					<li>
						<router-link to="/customer/financeprotect">금융소비자보호</router-link>
					</li>
					<li class="current">
						<router-link to="/customer/nego">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination">위법계약해지권</router-link>
					</li>
				</ul>
				<div>
					<div class="text_box">
						<div class="csfont2 title" style="color:#000;">금리인하요구권</div>

						<br><br>

						<div class="csfont1">1. 금리인하요구권이란?</div>
						<div class="csfont2">
						- 대출약정 당시와 비교하여 신용상태, 자산 등의 개선이 있는 경우 합리적인 근거를 바탕으로 금리인하를 요구할 수 있는 권리를 뜻합니다.<br>
						단, 당사평가 결과에 따라 금리인하가 되지 않을 수도 있으며, 신용상태와 관계없이 금리가 결정되는 상품은 금리인하요구권 대상에서 제외됩니다.
						</div>
						<br><br>

						<div class="csfont1">2. 대상상품</div>
						<div class="csfont2">
						- 일반대출, 일반리스, 할부금융
						</div>
						<br><br>

						<div class="csfont1">3. 요청근거</div>
						<div class="csfont2">
						가. 가계대출<br>
						① 신용등급 또는 개인신용평점의 개선 : 신용평가기관(NICE, KCB)의 신용등급 또는 개인신용평점 개선 시<br>
						② 연소득의 증가 : 취업, 승진, 연봉인상 등 신규 및 연장시점 대비 현재의 연소득이 현저히 증가한 경우<br>
						③ 자산의 증가 및 부채의 현저한 개선 등<br><br>

						나. 기업대출<br>
						① 재무상태의 현저한 개선<br>
						② 회사채 등급의 현저한 상승 : 기업신용평가등급 상승 등
						</div>
						<br><br>

						<div class="csfont1">4. 신청방법 및 필요서류</div>
						<div class="csfont2">
						가. 신청방법<br>
						① 고객센터(02-2047-7100) 및 본사로 필요서류 작성 후 제출<br>
						② 홈페이지 > 고객센터 > 온라인상담-민원신청 등록<br><br>

						나. 필요서류<br>
						② 금리인하요구권 신청서(당사양식)<br>
						당사양식은 (고객센터>서식자료실)로 가셔서 다운로드 하시면 됩니다.
						</div>
						<br><br>

						<div class="csfont1">5. 신청시기</div>
						<div class="csfont2">
						- 대출 취급일 또는 기한연장 시점부터 6개월이 경과하여야 신청이 가능합니다.
						</div>
						<br><br>

						<div class="csfont1">6. 심사결과 통지</div>
						<div class="csfont2">
						- 금리인하 요구를 받은 날부터 10영업일 이내(고객에게 자료의 보완을 요구하는 날부터 자료가 제출되는 날까지의 기간은 포함되지 않음)에 금리인하 요구 수용여부를 전화 또는 등기우편으로 고객에게 통지해 드립니다.
						</div>
						<br><br>

					</div>
				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Nego',
	methods:{
		toggleMenu(){
			document.querySelector('.m_terms_menu_wrapper .terms_menu').classList.toggle('on');
		},
	}
}
</script>

